import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ValidationListTable from '../../components/customTables/validationListTable';
import Loading from '../../components/shared/Loading';
import SearchHeader from '../../components/searchHeader';
import UnlistedEmployeeRoundModal from '../../components/modals/unlistedEmployeeRoundModal';

import { RootState } from '../../redux/store';
import { getValidationSubjectsToRoundForList } from '../../redux/actions/Employee.action';

import { ModalContainer } from '../../services/ModalPortal/ModalPortal.Styles';
import { ValidationContainer } from './styles/Validation.styles';
import ValidationSideNav from '../../components/sideNav/ValidationSideNav';

import { AuthUserProps, Employee, RoundHistory } from '../../config/interfaces';

export interface ValidationListProps {
  authorizedUser?: AuthUserProps;
  validationSubjectsToRound: (Employee & RoundHistory)[];
  isLoading: boolean;
  searchTerm: string;
  showModalFlag: boolean;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const ValidationList: FC<ValidationListProps> = ({
  authorizedUser,
  validationSubjectsToRound = [],
  isLoading,
  searchTerm,
  showModalFlag,
  setSearchTerm
}) => {
  const dispatch = useDispatch();

  const { validationFilter = 'all' } = useParams();

  useEffect(() => {
    if (authorizedUser?.hcaid)
      dispatch(
        getValidationSubjectsToRoundForList({
          hcaid: authorizedUser.hcaid
        })
      );
  }, []);

  return (
    <ValidationContainer id="Validation-Container">
      <ValidationSideNav />
      <div style={{ width: '100%' }}>
        <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {isLoading ? (
          <Loading centered={false} />
        ) : (
          <ValidationListTable
            validationFilter={validationFilter}
            searchTerm={searchTerm}
            validationRounds={validationSubjectsToRound}
          />
        )}
      </div>

      {showModalFlag && (
        <ModalContainer>
          <UnlistedEmployeeRoundModal />
        </ModalContainer>
      )}
    </ValidationContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    validationSubjectsToRound:
      state.EmployeeReducer.validationSubjectsToRoundForList,
    isLoading: state.EmployeeReducer.isLoadingSubjectsToRoundForList,
    showModalFlag: state.UserReducer.showUnlistedEmployeeModalFlag
  };
};

export default connect(mapReduxStateToProps)(ValidationList);
