import {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useMemo
} from 'react';
import { connect } from 'react-redux';

import { NeuSubLeftNav, NeuItem, NeuStepper, NeuLabel } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { logRoundProfileClick } from '../../utils/analyticsHelpers';

interface SideNavProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  // setSideDrawerData: Dispatch<SetStateAction<any>>;
  // tasks?: any;
  patientTasks?: any;
  employeeIssues?: any;
  employeeFeedback?: any;
  patientRoundHistory?: any;
  employeeRoundHistory?: any;
  selectedEmployee?: any;
  selectedPatient?: any;
  supportType?: string;
  type: string;
}

const HistorySideNav: FC<SideNavProps> = ({
  selectedTab,
  setSelectedTab,
  // setSideDrawerData,
  // tasks,
  patientTasks,
  employeeIssues,
  employeeFeedback,
  patientRoundHistory,
  employeeRoundHistory,
  type,
  selectedEmployee,
  selectedPatient,
  supportType
}) => {
  const numberOfIssues = useRef<any>(0);
  const numberOfFeedbacks = useRef<any>(0);
  const [numberIssue, setNumberIssue] = useState(0);
  const [numberFeedback, setNumberFeedback] = useState(0);

  const { logTrackingEvent } = useAnalyticsApi();

  const changeTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const hasPastTasks = useMemo(
    () => !(type === 'support' && supportType === 'csc'),
    [type, supportType]
  );

  useEffect(() => {
    numberOfIssues.current = 0;
    numberOfFeedbacks.current = 0;
    if (type === 'patient') {
      patientTasks.map((task: any) => {
        if (task.taskType === 'issue') {
          numberOfIssues.current += 1;
        } else if (task.taskType === 'feedback') {
          numberOfFeedbacks.current += 1;
        }
        return false;
      });
      setNumberIssue(numberOfIssues.current);
      setNumberFeedback(numberOfFeedbacks.current);
    } else {
      numberOfIssues.current += employeeIssues.length;
      numberOfFeedbacks.current += employeeFeedback.length;

      setNumberIssue(numberOfIssues.current);
      setNumberFeedback(numberOfFeedbacks.current);
    }
  }, [patientTasks, employeeIssues, employeeFeedback]);

  const handleClick = (e: any) => {
    switch (e.detail) {
      case 2:
        setSelectedTab('');
        break;
      default:
      // appInsights.trackEvent({
      //   name: 'History_Opened'
      // });
    }
  };

  return (
    <aside
      id="History-Side-Nav"
      style={{
        minHeight: 'calc(100vh - 176px)',
        flex: '1 0 16%',
        maxWidth: 196,
        display: 'flex',
        flexDirection: 'row',
        color: '#1F2532',
        fontWeight: 'normal'
      }}
    >
      <NeuSubLeftNav name="ORBIT HISTORY" color="plain" side="left">
        {!selectedPatient?.mrn?.includes('UL') && (
          <NeuItem
            id="Notes-Side-Nav"
            color={selectedTab === 'notes' ? 'primary-10' : 'plain-0'}
            class="pl-3"
            onClick={e => {
              if (type === 'patient' && selectedPatient?.notes) {
                logTrackingEvent(logRoundProfileClick('past-notes'));
                changeTab('notes');
                handleClick(e);
              }
              if (
                (type === 'employee' || type === 'support') &&
                selectedEmployee?.notes
              ) {
                // TODO: This is emitting csc need to check helper for csc or csrn
                logTrackingEvent(
                  logRoundProfileClick('past-notes', supportType)
                );
                changeTab('notes');
                handleClick(e);
              }
            }}
          >
            <NeuStepper slot="start" />
            <NeuLabel style={{ color: ' #191919' }}>
              Notes
              {type === 'patient'
                ? selectedPatient?.notes
                  ? ` (${selectedPatient?.notes?.length})`
                  : ' (N/A)'
                : selectedEmployee?.notes
                ? ` (${selectedEmployee?.notes?.length})`
                : ' (N/A)'}
            </NeuLabel>
          </NeuItem>
        )}
        <NeuItem
          id="Past-Rounds-Side-Nav"
          color={selectedTab === 'pastRounds' ? 'primary-10' : 'plain-0'}
          class="pl-3"
          onClick={e => {
            if (type === 'patient') {
              if (patientRoundHistory && patientRoundHistory.length) {
                logTrackingEvent(logRoundProfileClick('past-rounds'));
                changeTab('pastRounds');
                handleClick(e);
              }
            }
            if (type === 'employee' || type === 'support') {
              if (employeeRoundHistory && employeeRoundHistory.length) {
                // TODO: This is emitting csc need to check helper for csc or csrn
                logTrackingEvent(
                  logRoundProfileClick('past-rounds', supportType)
                );
                changeTab('pastRounds');
                handleClick(e);
              }
            }
          }}
        >
          <NeuStepper slot="start" />
          <NeuLabel style={{ color: ' #191919' }}>
            Past Rounds
            {type === 'patient'
              ? ` (${patientRoundHistory && patientRoundHistory.length})`
              : ` (${employeeRoundHistory && employeeRoundHistory.length})`}
          </NeuLabel>
        </NeuItem>
        {hasPastTasks && (
          <>
            <NeuItem
              id="Past-Issues-Side-Nav"
              color={selectedTab === 'pastIssues' ? 'primary-10' : 'plain-0'}
              class="pl-3"
              onClick={e => {
                if (numberIssue) {
                  logTrackingEvent(logRoundProfileClick('past-issues'));
                  changeTab('pastIssues');
                  handleClick(e);
                }
              }}
            >
              <NeuStepper slot="start" />
              <NeuLabel style={{ color: ' #191919' }}>
                Past Issues{` (${numberIssue})`}
              </NeuLabel>
            </NeuItem>

            <NeuItem
              id="Past-Feedback-Side-Nav"
              color={selectedTab === 'pastFeedback' ? 'primary-10' : 'plain-0'}
              class="pl-3"
              onClick={e => {
                if (numberFeedback) {
                  logTrackingEvent(logRoundProfileClick('past-feedback'));
                  changeTab('pastFeedback');
                  handleClick(e);
                }
              }}
            >
              <NeuStepper slot="start" />
              <NeuLabel style={{ color: ' #191919' }}>
                Past Feedback{` (${numberFeedback})`}
              </NeuLabel>
            </NeuItem>
          </>
        )}
      </NeuSubLeftNav>
    </aside>
  );
};

const mapReduxStateToProps = (state: any) => {
  return {
    patientTasks: state.TaskReducer.patientTasks,
    employeeIssues: state.TaskReducer.employeeIssues,
    employeeFeedback: state.TaskReducer.employeeFeedback,
    patientRoundHistory: state.ReportReducer.patientRoundHistory,
    employeeRoundHistory: state.ReportReducer.employeeRoundHistory,
    selectedPatient: state.UserReducer.selectedPatient,
    selectedEmployee: state.UserReducer.selectedEmployee
  };
};

export default connect(mapReduxStateToProps)(HistorySideNav);
