import { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import SupportListSettingsTable from './supportListSettingsTable/SupportListSettingsTable';

import {
  getCSCEmployeesConfig,
  updateCSCHiddenStatus
} from '../../../redux/actions/Employee.action';

import { useFeatures } from '../../../services/Features/features';
import { useUserRolesRef } from '../../../services/UserRoles';

import { RootState } from '../../../redux/store';

import { AuthUserProps, CSCEmployee } from '../../../config/interfaces';

interface CSCListSettingsProps {
  authorizedUser?: AuthUserProps;
  cscEmployees: CSCEmployee[];
  userId: string;
}

const CSCListSettingsTable: FC<CSCListSettingsProps> = ({
  authorizedUser,
  cscEmployees,
  userId
}) => {
  const dispatch = useDispatch();

  const {
    isAllSupportRounder,
    isCSCRounder,
    isMultipleRounders,
    isOnlyTwoTypeRounders
  } = useUserRolesRef();

  const { isCSCEnabled } = useFeatures();

  const hideEmployee = useCallback(
    (employee: CSCEmployee) => {
      dispatch(
        updateCSCHiddenStatus({
          userId,
          employeeId: employee.hcaid,
          isHidden: !employee.isHidden
        })
      );
    },
    [userId]
  );

  useEffect(() => {
    if (isCSCEnabled) dispatch(getCSCEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser, isCSCEnabled]);

  return (
    <SupportListSettingsTable
      employees={cscEmployees}
      isFacilitySelectorEnabled={
        (!isMultipleRounders && isCSCRounder) ||
        (isOnlyTwoTypeRounders && isAllSupportRounder)
      }
      isTableEnabled={isCSCEnabled}
      type="csc"
      hideEmployee={hideEmployee}
    />
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    cscEmployees: state.EmployeeReducer.cscEmployees,
    userId: state.AuthorizedUser.authorizedUser.hcaid
  };
};

export default connect(mapReduxStateToProps)(CSCListSettingsTable);
