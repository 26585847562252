/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuTableRow, NeuTableHeading, NeuButton } from '@neutron/react';
/**
 * Internal Imports

 */
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import { toast } from '../../../services/Toast';
import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import { getEmployeesConfig } from '../../../redux/actions/Employee.action';
import { setToastType } from '../../../redux/actions/Toast.action';
import { removeUserAsDelegate } from '../../../redux/actions/User.action';

import { handleBlobPicUrl, userProfilePicUrl } from '../../../utils/helpers';

/**
 * Global Type Definition Imports
 */
import { AuthUserProps, ListsDelegatedToMe } from '../../../config/interfaces';

/**
 * Style Imports
 */
import {
  ContentHeader,
  TextInTable,
  RemoveButton,
  EmployeeAvatar,
  DelegatesConfigContainer,
  DelegatedToMeContainer,
  DelegatedToMeTable,
  DelegatedToMeTableBody,
  EmployeeNameInTable,
  Meta,
  DelegateSlot
} from './DelegatedToMeTable.styles';

import defaultImage from '../../../assets/images/defaultImage.png';

interface DelegatedToMeProps {
  authorizedUser?: AuthUserProps;
  delegatedToMeList?: ListsDelegatedToMe[];
  loadingDelegatesToMe: boolean;
  sasToken: string;
}

const DelegatedToMeListTable: FC<DelegatedToMeProps> = ({
  authorizedUser,
  delegatedToMeList,
  loadingDelegatesToMe,
  sasToken
}) => {
  const dispatch = useDispatch();

  const [filteredList, setFilteredList] = useState<ListsDelegatedToMe[]>([]);
  const [active, setActive] = useState<string>('lastName');
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');

  const handleRemoveEmployee = (delegate: ListsDelegatedToMe) => {
    dispatch(setToastType({ type: 'Delete Delegated To Me' }));
    toast(
      'This action cannot be undone.',
      'info',
      null,
      400,
      true,
      `The owner will have to add you as a delegate.`,
      () => {
        const payload = {
          userId: delegate.hcaid,
          delegateId: authorizedUser?.hcaid
        };
        dispatch(removeUserAsDelegate(payload));
      }
    );
  };

  const sortByHeading = (heading: string) => {
    const stateCopy = delegatedToMeList?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...delegatedToMeList]
      : [];

    if (sortDir === 'desc') {
      setSortDir('asc');
      stateCopy.sort((a: ListsDelegatedToMe, b: ListsDelegatedToMe) =>
        a[heading as keyof ListsDelegatedToMe]?.toLowerCase() >
        b[heading as keyof ListsDelegatedToMe]?.toLowerCase()
          ? 1
          : -1
      );
      setActive(heading);
    }
    if (sortDir === 'asc') {
      setSortDir('desc');
      stateCopy.sort((a: ListsDelegatedToMe, b: ListsDelegatedToMe) =>
        a[heading as keyof ListsDelegatedToMe]?.toLowerCase() >
        b[heading as keyof ListsDelegatedToMe]?.toLowerCase()
          ? -1
          : 1
      );
      setActive(heading);
    }

    setFilteredList(stateCopy);
  };

  useEffect(() => {
    dispatch(getEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser]);

  useEffect(() => {
    if (delegatedToMeList?.length) {
      // Sorting the list A-Z for initial display
      const sorted = delegatedToMeList.sort(
        (a: ListsDelegatedToMe, b: ListsDelegatedToMe) =>
          a.employeeName > b.employeeName ? 1 : -1
      );
      setFilteredList(sorted);
    } else {
      setFilteredList([]);
    }
  }, [delegatedToMeList]);

  return (
    <DelegatedToMeContainer id="Delegated-To-Me-Container">
      <DelegatesConfigContainer>
        <ContentHeader style={{ paddingTop: '15px' }} />
      </DelegatesConfigContainer>
      <DelegatedToMeTable>
        <NeuTableRow
          style={{ backgroundColor: 'white' }}
          columns="{'Delegate': '34%','Department':'27%', 'Title': '27%', 'Delete':'12'}"
          header
        >
          <NeuTableHeading
            id="Delegated-To-Me-List-Table-Delegate-Column"
            slot="Delegate"
            icon={
              !(active === 'lastName')
                ? 'asc'
                : sortDir === 'asc'
                ? 'desc'
                : 'asc'
            }
            onClick={() => sortByHeading('lastName')}
            active={active === 'lastName'}
          >
            Delegate
          </NeuTableHeading>
          <NeuTableHeading
            id="Delegated-To-Me-List-Table-Department-Column"
            slot="Department"
            icon={
              !(active === 'department')
                ? 'asc'
                : sortDir === 'asc'
                ? 'desc'
                : 'asc'
            }
            onClick={() => sortByHeading('department')}
            active={active === 'department'}
          >
            Department
          </NeuTableHeading>
          <NeuTableHeading
            id="Delegated-To-Me-List-Table-Title-Column"
            slot="Title"
            icon={
              !(active === 'title') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
            }
            onClick={() => sortByHeading('title')}
            active={active === 'title'}
          >
            Title
          </NeuTableHeading>
          <NeuTableHeading
            id="Delegated-To-Me-List-Table-Delete-Column"
            slot="Delete"
            icon="none"
          />
        </NeuTableRow>
        {loadingDelegatesToMe ? (
          <Loading centered={false} />
        ) : filteredList.length === 0 && !loadingDelegatesToMe ? (
          <NoDataSplashView type="delegated-to-me" />
        ) : (
          <DelegatedToMeTableBody>
            {filteredList?.map((delegate: ListsDelegatedToMe) => {
              return (
                delegate && (
                  <NeuTableRow
                    columns="{'Delegate': '34%','Department':'27%', 'Title': '27%','Delete':'12'}"
                    size="normal"
                    key={delegate.hcaid}
                    style={{ alignItems: 'center' }}
                  >
                    <DelegateSlot slot="Delegate">
                      {sasToken ? (
                        <EmployeeAvatar
                          src={
                            handleBlobPicUrl(delegate.pictureUrl, sasToken) ??
                            userProfilePicUrl(delegate.hcaid, sasToken)
                          }
                          alt="Delegate Image"
                          onError={e => {
                            e.currentTarget.src = defaultImage;
                            return null;
                          }}
                        />
                      ) : (
                        <EmployeeAvatar
                          src={defaultImage}
                          alt="Default Image"
                        />
                      )}
                      <div>
                        <EmployeeNameInTable>
                          {delegate?.employeeName}
                        </EmployeeNameInTable>
                        <TextInTable>{delegate?.hcaid}</TextInTable>
                      </div>
                    </DelegateSlot>
                    <Meta slot="Department">
                      <TextInTable>
                        {delegate?.department || 'Unknown'}
                      </TextInTable>
                    </Meta>
                    <Meta slot="Title">
                      <TextInTable>{delegate?.title || 'Unknown'}</TextInTable>
                    </Meta>
                    <RemoveButton
                      slot="Delete"
                      onClick={() => handleRemoveEmployee(delegate)}
                    >
                      <NeuButton color="#CE3637" fill="outline" size="small">
                        Remove
                      </NeuButton>
                    </RemoveButton>
                  </NeuTableRow>
                )
              );
            })}
          </DelegatedToMeTableBody>
        )}
      </DelegatedToMeTable>
    </DelegatedToMeContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    delegatedToMeList: state.EmployeeReducer.employeesDelegatedToMe,
    loadingDelegatesToMe: state.EmployeeReducer.loadingMyEmployees,
    sasToken: state.ConfigReducer.sasToken
  };
};
export default connect(mapReduxStateToProps)(DelegatedToMeListTable);
