/**
 * External Imports
 */
import { SyntheticEvent } from 'react';
import {
  add,
  addDays,
  addMinutes,
  addMonths,
  addQuarters,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  format,
  parse,
  subMinutes,
  subDays
} from 'date-fns';
/**
 * Internal Imports
 */
import API from '../services/API';

const { v4: uuidv4 } = require('uuid');

const date = {
  isOrbitTimestamp(dateStr: string) {
    return /^(\d){10}$|^(\d){12}$|^(\d){14}$/g.test(dateStr);
  },
  datePrettier(dateStr: string) {
    if (dateStr.includes('/')) {
      return dateStr;
    }

    return format(
      new Date(
        Number(dateStr.slice(0, 4)),
        Number(dateStr.slice(4, 6)) - 1,
        Number(dateStr.slice(6, 8))
      ),
      'M/dd/yyyy'
    );
  },
  differenceBetweenDays(startDay: Date, endDay: Date) {
    return differenceInDays(startDay, endDay);
  },
  beginningOfYear() {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}0101000000`;
  },
  parseDate(dateStr: string) {
    const dt = parse(
      dateStr,
      dateStr.length === 10
        ? 'yyyyMMddHH'
        : dateStr.length === 12
        ? 'yyyyMMddHHmm'
        : 'yyyyMMddHHmmss',
      new Date()
    );
    return subMinutes(dt, dt.getTimezoneOffset());
  },
  parseStopDate(dateStr: string) {
    const dt = parse(dateStr, 'yyyyMMddHHmm', new Date());
    return subMinutes(dt, dt.getTimezoneOffset());
  },
  parseYearMonthDay(dateStr: string) {
    const dt = parse(dateStr, 'yyyyMMdd', new Date());
    return subMinutes(dt, dt.getTimezoneOffset());
  },
  parseMonthDayYear(dateStr: string) {
    const dt = parse(dateStr, 'MM/dd/yyyy', new Date());
    return subMinutes(dt, dt.getTimezoneOffset());
  },
  parseMonthDayYearToYearMonthDay(dateStr: string) {
    const dt = parse(dateStr, 'MM/dd/yyyy', new Date());
    return format(subMinutes(dt, dt.getTimezoneOffset()), 'yyyyMMdd');
  },
  now() {
    const dt = new Date();
    return format(addMinutes(dt, dt.getTimezoneOffset()), 'yyyyMMddHHmmss');
  },

  convertDateToUTCTimestamp(dt: Date) {
    return format(addMinutes(dt, dt.getTimezoneOffset()), 'yyyyMMddHHmmss');
  },
  newHireRoundDate(startDay: string, numberOfDaysToAdd: number) {
    const addNumberOfDays = addDays(
      new Date(
        Number(startDay.slice(0, 4)),
        Number(startDay.slice(4, 6)) - 1,
        Number(startDay.slice(6, 8))
      ),
      numberOfDaysToAdd
    );

    return format(new Date(addNumberOfDays), 'yyyyMMddHHmmss');
  },
  dayOfYr(dateStr: string) {
    if (dateStr.includes('/')) {
      return dateStr;
    }
    return this.isOrbitTimestamp(dateStr)
      ? format(this.parseDate(dateStr), 'M/dd/yyyy')
      : 'Unknown';
  },
  dayOfYrFull(dateStr: string) {
    if (dateStr.includes('/')) {
      return dateStr;
    }
    return this.isOrbitTimestamp(dateStr)
      ? format(this.parseDate(dateStr), 'MM/dd/yyyy')
      : 'Unknown';
  },
  convertToDayOfYear(dt: Date) {
    return format(dt, 'yyyy-MM-dd');
  },
  localDayOfYearDate(dateStr: string) {
    return parse(dateStr, 'yyyy-MM-dd', new Date());
  },
  addDayToDayOfYearDate(dateStr: string) {
    const dt = this.localDayOfYearDate(dateStr);
    return this.convertToDayOfYear(addDays(dt, 1));
  },
  isOverOneDayOld(dateStr: string) {
    return this.isOrbitTimestamp(dateStr)
      ? differenceInHours(new Date(), this.parseDate(dateStr)) >= 24
      : true;
  },
  isOverInDays(dateStr: string, days: number) {
    return this.isOrbitTimestamp(dateStr)
      ? differenceInDays(new Date(), this.parseDate(dateStr)) >= days
      : true;
  },
  isOverInWeeks(dateStr: string, weeks: number) {
    return this.isOrbitTimestamp(dateStr)
      ? differenceInWeeks(new Date(), this.parseDate(dateStr)) >= weeks
      : true;
  },
  isOverInMonths(dateStr: string, months: number) {
    return (
      differenceInMonths(
        new Date(),
        dateStr.includes('/')
          ? this.parseMonthDayYear(dateStr)
          : this.parseYearMonthDay(dateStr)
      ) >= months
    );
  },
  isOverInYears(dateStr: string, years: number) {
    return (
      differenceInYears(
        new Date(),
        dateStr.includes('/')
          ? this.parseMonthDayYear(dateStr)
          : this.parseYearMonthDay(dateStr)
      ) >= years
    );
  },
  localTimeOfDay(dateStr: string) {
    return this.isOrbitTimestamp(dateStr)
      ? format(this.parseDate(dateStr), 'h:mm a')
      : 'Unknown';
  },
  formatFilterStartDate(dateStr: string) {
    const dt = parse(dateStr, 'yyyy-MM-dd', new Date());
    return format(addMinutes(dt, dt.getTimezoneOffset()), 'yyyyMMddHHmmss');
  },
  formatFilterEndDate(dateStr: string) {
    const dt = parse(dateStr, 'yyyy-MM-dd', new Date());
    return format(
      addMinutes(dt, dt.getTimezoneOffset() + 1439),
      'yyyyMMddHHmmss'
    );
  },
  dateTimeCombination(dateStr: string) {
    return this.isOrbitTimestamp(dateStr)
      ? format(this.parseDate(dateStr), 'Pp')
      : 'Unknown';
  },
  dateTimeCombinationWithSeconds(dateStr: string) {
    return this.isOrbitTimestamp(dateStr)
      ? format(this.parseDate(dateStr), 'pp')
      : 'Unknown';
  },
  convertToDateTime(dt: Date) {
    return format(dt, "yyyy-MM-dd'T'HH:mm");
  },
  datePrettyString(dateStr: string | null) {
    if (!dateStr) return '';
    if (dateStr.includes('/')) return dateStr;
    // Custom function only used for display date string irrespective of timezone
    return `${dateStr.slice(4, 6)}/${dateStr.slice(6, 8)}/${dateStr.slice(
      0,
      4
    )}`;
  },
  datePrettyDashes(dateStr: string) {
    // Custom function only used for display date string irrespective of timezone
    return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(
      6,
      8
    )}`;
  },
  isToday(dt: Date) {
    const today = new Date();
    return (
      dt.getDate() === today.getDate() &&
      dt.getMonth() === today.getMonth() &&
      dt.getFullYear() === today.getFullYear()
    );
  },
  formatFilterStartDayToUTCTimestamp(dateStr: string) {
    const dt = parse(dateStr, 'yyyyMMdd', new Date());
    return format(addMinutes(dt, dt.getTimezoneOffset()), 'yyyyMMddHHmmss');
  },
  formatFilterEndDayToUTCTimestamp(dateStr: string) {
    const dt = parse(dateStr, 'yyyyMMdd', new Date());
    return format(addMinutes(dt, dt.getTimezoneOffset() - 1), 'yyyyMMddHHmmss');
  },
  addMonthsToDate(dt: Date, numberOfMonths: number) {
    const dte = addMonths(dt, numberOfMonths);
    return format(new Date(dte), 'yyyyMMddHHmmss');
  },
  addQuartersToDate(dt: Date, numberOfQuarters: number) {
    const dte = addQuarters(dt, numberOfQuarters);
    return format(new Date(dte), 'yyyyMMddHHmmss');
  },
  numericMonthYearDate(dateStr: string) {
    if (!dateStr || dateStr.length === 0) {
      return 'Unknown';
    }
    return format(
      new Date(
        Number(dateStr.slice(0, 4)),
        Number(dateStr.slice(4, 6)) - 1,
        Number(dateStr.slice(6, 8))
      ),
      'MM/yyyy'
    );
  },
  alphanumericMonthYearDate(dateStr: string | null) {
    if (!dateStr || dateStr.length === 0) {
      return 'Unknown';
    }
    return format(
      new Date(
        Number(dateStr.slice(0, 4)),
        Number(dateStr.slice(4, 6)) - 1,
        Number(dateStr.slice(6, 8))
      ),
      'LLL yyyy'
    );
  }
};

const text = {
  capFirst(str: string) {
    return str
      ? str
          .split(' ')
          .map((s: string) => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(' ')
      : '';
  },
  lastFirst(str?: string | null) {
    return str ? `${str.split(' ')[1]}, ${str.split(' ')[0]}` : '';
  },
  getInitials(str: string) {
    return str
      .split(' ')
      .map(n => n[0])
      .join('');
  },
  escCSVChars(str: string) {
    return str ? str.replace(/"/g, '""') : '';
  }
};

const unCamelCase = (str: string) => {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, txt => txt.toUpperCase())
  );
};

// Unknown Employee Hire Date
const employeeHireDateHelper = (employeeHireDate: string) => {
  const pattern =
    /^([0-9]{8}){1}$/.test(employeeHireDate) ||
    /^([0-9]{2}?[/]{1}?[0-9]{2}?[/]{1}?[0-9]{4}){1}$/.test(employeeHireDate);
  if (pattern) {
    return employeeHireDate;
  }
  return null;
};

// Reverse Name
const reverseName = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return `${lastName.trim().toUpperCase()}, ${firstName
      .trim()
      .toUpperCase()}`;
  }
  return '';
};

const toTitleCase = (str: string | undefined) => {
  // Title Case
  return str?.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};
// Title Case Names & Capitalize Abbreviations
const toTitleCaseSpecial = (s: string) => {
  const pattern = /(?<=\s|^|[-(])[A-Z]{1,4}\b|[A-Z]+/g;

  return s.replace(pattern, match => {
    if (/^[A-Z]{1,4}$/.test(match) || match === 'HCAHCA' || match === 'ADMIN') {
      return match;
    }
    return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
  });
};

const abbreviatedName = (firstName?: string, lastName?: string) => {
  const abbreviated =
    lastName && firstName
      ? `${lastName.slice(0, 3).toUpperCase()}, ${firstName
          .slice(0, 3)
          .toUpperCase()}`
      : '';
  return abbreviated;
};

const orbitTimestampToMMDDYYYY = (orbitTimestamp: string) => {
  const yyyy = orbitTimestamp?.substring(0, 4);
  const mm = orbitTimestamp?.substring(4, 6);
  const dd = orbitTimestamp?.substring(6, 8);
  return { yyyy, mm, dd };
};

// Utility function to create an ID in our format idType:FacilidId:uuid (types: task, round, template... etc.)
const createAnyId = (idType: string, facilityId: string) => {
  const createdId = `${idType}:${facilityId}:${uuidv4()}`;
  return createdId;
};

async function getImage(user34: string) {
  const bearer = window.localStorage.getItem('authToken');
  const imgUrl = `${API.getUserImageUrl}?userId=${user34}`;

  const response = await fetch(imgUrl, {
    headers: { Authorization: bearer || '' }
  });
  const blob = await response.blob();
  const imageObjectURL = URL.createObjectURL(blob);
  return imageObjectURL;
}

const compareObjByStringKey =
  (key: string) =>
  (a: any = {}, b: any = {}) =>
    a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;

const setLastPath = (route: string) => {
  window.localStorage.setItem('orbitLastPath', route);
};

const defaultSasImage = (imgUrl: string, sasToken: string) => {
  return `${
    imgUrl && /^(http|https):/.test(imgUrl)
      ? imgUrl.split('/user-pictures/')[0]
      : process.env.REACT_APP_PIC_BLOB_URL
  }/user-pictures/default.png${sasToken}`;
};

const handleBlobPicUrl = (imgUrl: string, sasToken: string) =>
  imgUrl && /^(http|https):/.test(imgUrl) ? `${imgUrl}${sasToken}` : null;

const replaceWithDefaultImage = (
  e: SyntheticEvent<HTMLImageElement | HTMLNeuImageElement, Event>,
  imgUrl: string,
  sasToken: string
) => {
  const defaultImg = imgUrl ? defaultSasImage(imgUrl, sasToken) : '';
  const target = e.target as HTMLImageElement;
  if (target.src) target.src = defaultImg;
  return null;
};

// Format phone number
const formatPhoneNumber = (phone: string) => {
  if (phone) {
    const phoneNumber = phone.trim().toString();
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(phoneNumber)) {
      return phoneNumber.replace(phoneRegex, '($1) $2-$3');
    }
    return phoneNumber;
  }
  return '';
};

const calculateStayTime = (hours: number) =>
  hours >= 24
    ? `${Math.floor(hours / 24)}d`
    : hours === 0
    ? `<1h`
    : `${hours}h`;

const yearsOfService = (dateStr: string) => {
  const months = differenceInMonths(
    new Date(),
    parse(
      dateStr,
      dateStr.includes('/') ? 'MM/dd/yyyy' : 'yyyyMMdd',
      new Date()
    )
  );
  return months === 0
    ? '<1m'
    : months < 12
    ? `${months}m`
    : `${Math.floor(months / 12)}y ${Math.floor(months % 12)}m`;
};

const isHca34 = (id: string) => {
  return /^[A-Za-z]{3}[0-9]{1,4}?$/.test(id);
};

const isRoundOverdue = (
  todayDate: Date,
  lastRoundDate: Date,
  frequency: number
) => {
  // is round overdue?
  const pastDueDate = subDays(todayDate, frequency);
  return lastRoundDate <= pastDueDate;
};

const isNewHireStrategy = (
  effectiveStartDate: string,
  newHireFrequency: number
) => {
  const effectiveStartDateNum = new Date(
    Number(effectiveStartDate.slice(0, 4)),
    Number(effectiveStartDate.slice(4, 6)) - 1,
    Number(effectiveStartDate.slice(6, 8))
  );
  const today = new Date();
  const dayDifference = date.differenceBetweenDays(
    today,
    effectiveStartDateNum
  );
  return dayDifference <= newHireFrequency;
};

// function to create pictureUrl when no pictureUrl exist
const userProfilePicUrl = (hcaid: string, sasToken: string) => {
  return `${process.env.REACT_APP_PIC_BLOB_URL}/user-pictures/${hcaid}.png${sasToken}`;
};

// function to appropriately format roundingtype and rounders labeling
// TODO: Split into view helper and rounding type helper
const formatRoundingType = (roundingType: string) => {
  let roundType;
  switch (roundingType.toLowerCase()) {
    case 'csc/list':
      roundType = 'CSC List';
      break;
    case 'cned/list':
      roundType = 'CNEd List';
      break;
    case 'csc':
    case 'csrn':
      roundType = 'CSC';
      break;
    case 'cned':
      roundType = 'CNEd';
      break;
    case 'csrnrounder':
      roundType = 'CSC Rounder';
      break;
    case 'cnedrounder':
      roundType = 'CNEd Rounder';
      break;
    case 'templateconfigurator':
    case 'useradmin':
    case 'enterpriseadmin':
    case 'employeerounder':
    case 'patientrounder':
    case 'validationrounder':
      roundType = text.capFirst(roundingType.split(/(?=[A-Z])/).join(' '));
      break;
    default:
      roundType = text.capFirst(roundingType);
      break;
  }
  return roundType;
};

// TODO: Probably not necessary
const calculateValidationNextRound = (
  lastRoundDate: string,
  competencies: string[]
) => {
  let adjustedRoundDate;

  if (lastRoundDate && date.isOrbitTimestamp(lastRoundDate)) {
    const roundDate = date.parseDate(lastRoundDate);

    if (competencies.includes('Val_NLR_E')) {
      adjustedRoundDate = date.convertDateToUTCTimestamp(
        add(roundDate, { years: 1 })
      );
    } else if (competencies.includes('Val_NLR_D')) {
      adjustedRoundDate = date.convertDateToUTCTimestamp(
        add(roundDate, { months: 6 })
      );
    } else if (competencies.includes('Val_NLR_C')) {
      adjustedRoundDate = date.convertDateToUTCTimestamp(
        add(roundDate, { weeks: 2 })
      );
    }
  }
  return adjustedRoundDate;
};

export {
  date,
  text,
  calculateValidationNextRound,
  getImage,
  unCamelCase,
  toTitleCase,
  toTitleCaseSpecial,
  employeeHireDateHelper,
  createAnyId,
  orbitTimestampToMMDDYYYY,
  compareObjByStringKey,
  setLastPath,
  handleBlobPicUrl,
  replaceWithDefaultImage,
  formatPhoneNumber,
  calculateStayTime,
  reverseName,
  abbreviatedName,
  isHca34,
  isRoundOverdue,
  isNewHireStrategy,
  yearsOfService,
  userProfilePicUrl,
  formatRoundingType
};
