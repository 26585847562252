/**
 * External Imports
 */
import styled from 'styled-components';
import { NeuImage } from '@neutron/react';

export const IconDiv = styled.div`
  padding-top: 10px;
`;
export const AvatarDiv = styled.div`
  padding-top: 5px;
`;
export const EmployeeInfoDiv = styled.div`
  display: flex;
`;
export const EmployeeAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
export const OverdueImg = styled(NeuImage)`
  margin-right: 2px;
`;

export const DeptStartContainer = styled.div`
  textalign: center;
`;
export const StartDateContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-right: 2px;
`;
export const NewHireIconContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-top: 4px;
`;
