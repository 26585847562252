// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  searchedEmployees: [],
  searchedEmpsToShare: [],
  employeesToRound: [],
  csrnSubjectsToRoundForList: [],
  cnedSubjectsToRoundForList: [],
  validationSubjectsToRoundForList: [],
  loadingEmployeesToRound: false,
  loadingMyEmployees: false,
  employees: [],
  employeesDelegatedTo: [],
  employeesDelegatedToMe: [],
  myEmployees: [],
  cscEmployees: [],
  cnedEmployees: [],
  validationEmployees: [],
  employeeAccountDetails: [],
  loadingPostNotes: false,
  loadingRemoveNotes: false,
  loadingSupportList: false,
  isLoadingSubjectsToRoundForList: false,
  // TODO: Is this the right type for since the data type is Object
  selectedDelegatedEmployee: '',
  depts: [],
  selectedDepts: [],
  delegateSettings: {}
};

const EmployeeReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.EMPLOYEE.GET_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employees: [...action.data]
      };
    }

    case ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        searchedEmployees: action.data
      };
    }

    case ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES_TO_SHARE_SUCCESS: {
      return {
        ...state,
        searchedEmpsToShare: action.data
      };
    }

    case ACTIONS.EMPLOYEE.CLEAR_EMPLOYEE_SEARCH: {
      return {
        ...state,
        searchedEmployees: []
      };
    }

    case ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND: {
      return {
        ...state,
        loadingEmployeesToRound: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND_SUCCESS: {
      return {
        ...state,
        employeesToRound: action.data,
        loadingEmployeesToRound: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND_FAILURE: {
      return {
        ...state,
        employeesToRound: [],
        loadingEmployeesToRound: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_VALIDATION_SUBJECTS_TO_ROUND_FOR_LIST: {
      return {
        ...state,
        isLoadingSubjectsToRoundForList: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_VALIDATION_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS: {
      return {
        ...state,
        validationSubjectsToRoundForList: action.data,
        isLoadingSubjectsToRoundForList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST: {
      return {
        ...state,
        isLoadingSubjectsToRoundForList: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS: {
      return {
        ...state,
        csrnSubjectsToRoundForList: action.data,
        isLoadingSubjectsToRoundForList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST: {
      return {
        ...state,
        isLoadingSubjectsToRoundForList: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS: {
      return {
        ...state,
        cnedSubjectsToRoundForList: action.data,
        isLoadingSubjectsToRoundForList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_CSC_EMPLOYEES_CONFIG: {
      return {
        ...state,
        loadingSupportList: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_CSC_EMPLOYEES_CONFIG_SUCCESS: {
      return {
        ...state,
        cscEmployees: action.data,
        loadingSupportList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_CSC_EMPLOYEES_CONFIG_FAILURE: {
      return {
        ...state,
        loadingSupportList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG: {
      return {
        ...state,
        loadingSupportList: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG_SUCCESS: {
      return {
        ...state,
        cnedEmployees: action.data,
        loadingSupportList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG_FAILURE: {
      return {
        ...state,
        loadingSupportList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_VALIDATION_EMPLOYEES_CONFIG: {
      return {
        ...state,
        loadingSupportList: true
      };
    }

    case ACTIONS.EMPLOYEE.GET_VALIDATION_EMPLOYEES_CONFIG_SUCCESS: {
      return {
        ...state,
        validationEmployees: action.data,
        loadingSupportList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_VALIDATION_EMPLOYEES_CONFIG_FAILURE: {
      return {
        ...state,
        loadingSupportList: false
      };
    }

    case ACTIONS.EMPLOYEE.GET_EMPLOYEES_DELEGATED_TO_SUCCESS: {
      return {
        ...state,
        employeesDelegatedTo: action.data
      };
    }
    case ACTIONS.EMPLOYEE.GET_EMPLOYEES_DELEGATED_TO_ME_SUCCESS: {
      return {
        ...state,
        employeesDelegatedToMe: action.data
      };
    }
    case ACTIONS.EMPLOYEE.GET_DELEGATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        delegateSettings: action.data
      };
    }
    case ACTIONS.EMPLOYEE.GET_MY_EMPLOYEES: {
      return {
        ...state,
        loadingMyEmployees: true
      };
    }
    case ACTIONS.EMPLOYEE.GET_MY_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        myEmployees: action?.data,
        loadingMyEmployees: false
      };
    }
    case ACTIONS.EMPLOYEE.REMOVE_FROM_MY_EMPLOYEES: {
      // remove particular employee from the list
      const updatedListToDisplay = state.myEmployees.filter((item: any) => {
        return item.hcaid !== action?.data;
      });
      return {
        ...state,
        myEmployees: updatedListToDisplay
      };
    }
    case ACTIONS.EMPLOYEE.GET_EMPLOYEE_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        employeeAccountDetails: action.data
      };
    }
    case ACTIONS.EMPLOYEE.ADD_NOTE: {
      return {
        ...state,
        loadingPostNotes: true
      };
    }
    case ACTIONS.EMPLOYEE.ADD_NOTE_SUCCESS: {
      return {
        ...state,
        loadingPostNotes: false
      };
    }
    case ACTIONS.EMPLOYEE.REMOVE_NOTE: {
      return {
        ...state,
        loadingRemoveNotes: true
      };
    }
    case ACTIONS.EMPLOYEE.REMOVE_NOTE_SUCCESS: {
      return {
        ...state,
        loadingRemoveNotes: false
      };
    }
    case ACTIONS.EMPLOYEE.SET_DELEGATED_EMPLOYEE: {
      return {
        ...state,
        selectedDelegatedEmployee: action.data
      };
    }
    case ACTIONS.EMPLOYEE.CLEAR_EMPLOYEES_TO_ROUND: {
      return {
        ...state,
        employeesToRound: [],
        selectedDelegatedEmployee: ''
      };
    }
    case ACTIONS.EMPLOYEE.SET_DEPTS: {
      return {
        ...state,
        depts: action.data
      };
    }
    case ACTIONS.EMPLOYEE.SET_SELECTED_DEPTS: {
      return {
        ...state,
        selectedDepts: action.data
      };
    }
    case ACTIONS.EMPLOYEE.CLEAR_ALL_DEPTS: {
      return {
        ...state,
        depts: [],
        selectedDepts: []
      };
    }
    default:
      return tempState;
  }
};

export default EmployeeReducer;
