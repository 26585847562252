/**
 * External Imports
 */
import styled from 'styled-components';
import { NeuImage, NeuLabel, NeuTableRow } from '@neutron/react';

export const IconDiv = styled.div`
  padding-top: 10px;
`;

export const AvatarDiv = styled.div`
  padding-top: 5px;
`;

export const EmployeeInfoDiv = styled.div`
  display: flex;
`;

export const EmployeeAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const OverdueImg = styled(NeuImage)`
  margin-right: 2px;
`;

export const DeptStartContainer = styled.div`
  text-align: right;
`;

export const StartDateContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-right: 44%;
`;

export const NewHireIconContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-top: 4px;
`;

export const ToDoLabel = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
`;

export const ToDoRow = styled(NeuTableRow)`
  background-color: white;
  height: 64px;
  align-items: center;
`;

export const CompleteRow = styled(NeuTableRow)`
  background-color: #f6f6f6;
  height: 64px;
  align-items: center;
`;
