import styled from 'styled-components';

export const SubToolbar = styled.div`
  position: static;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 58px;
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 5px;
  font-weight: 700;
  padding-left: 0px;
`;

export const PatientProfileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 40px;
  background: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  color: #4b4b4b;
  font-size: 14;
  line-height: 22px;
  font-style: normal;
  font-weight: 700;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
`;

export const TitleContainer = styled.div`
  position: absolute;
  width: 112px;
  height: 22px;
  left: 20px;
  top: 0px;
  font-size: 14px;
  line-height: 22px;
  color: #2075ad;
  flex-direction: row;
`;

export const Title = styled.p`
  color: #093865;
  font-weight: bold;
  font-size 16px;
  line-height: 26px;
`;

export const PatientInfo = styled.span`
  white-space: pre;
  font-weight: normal;
  font-weight: 400;
  color: #686868;
`;

export const HeaderTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b;
`;
