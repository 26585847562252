import { call, delay, put, all, select } from 'redux-saga/effects';
import { differenceInHours, parse } from 'date-fns';
import { getData, postData } from '../../services/API/axios';
import { getAuthorizedUser } from '../actions/Auth.action';
import { getSassToken } from '../actions/Config.action';
import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import { getUsers } from './Admin.saga';
import {
  date as dt,
  compareObjByStringKey,
  isHca34,
  formatRoundingType
} from '../../utils/helpers';
import { getEmployeesToRound } from '../actions/Employee.action';

// Patient sagas
export function* getPatientsToRound(action) {
  // added params is facilityId
  const params = new URLSearchParams(action.data); // making query string
  const url = `${API.getPatientsToRoundUrl}?${params.toString()}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanResponse = data.map(patient => {
        if (patient.admitTime) {
          const today = new Date();
          const admitTime = patient?.admitTime
            ? patient.admitTime.length === 12
              ? parse(patient?.admitTime, 'yyyyMMddHHmm', new Date())
              : patient.admitTime.length === 14
              ? parse(patient?.admitTime, 'yyyyMMddHHmmss', new Date())
              : undefined
            : undefined;
          const lastRoundDate =
            patient?.lastRound?.roundDate &&
            ((dt.isOrbitTimestamp(patient?.lastRound?.roundDate) &&
              `${dt.dayOfYr(patient.lastRound.roundDate)} @ ${dt.localTimeOfDay(
                patient.lastRound.roundDate
              )}`) ||
              'Unknown');

          let roundStatus;
          const lengthOfStay = admitTime
            ? differenceInHours(today, admitTime)
            : -1;
          if (
            patient?.lastRound?.roundDate &&
            dt.isOrbitTimestamp(patient?.lastRound?.roundDate) &&
            dt.isToday(dt.parseDate(patient.lastRound.roundDate))
          ) {
            roundStatus = 'Complete';
          } else {
            roundStatus = 'To Do';
          }
          const fullName = `${patient.firstName} ${patient.lastName}`;

          return {
            ...patient,
            roundStatus,
            lastRoundDate: patient?.lastRound?.roundDate,
            lastRoundedBy: patient?.lastRound?.roundedBy,
            formattedLastRoundDate: lastRoundDate,
            lengthOfStay,
            fullName
          };
        }
        const fullName = `${patient.firstName} ${patient.lastName}`;

        return {
          ...patient,
          roundStatus: 'To Do',
          lastRoundDate: null,
          lastRoundedBy: null,
          formattedLastRoundDate: null,
          fullName
        };
      });

      yield put({
        type: ACTIONS.USER.GET_PATIENTS_TO_ROUND_SUCCESS,
        data: cleanResponse
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getRounds() {
  const url = `${API.getRoundsUrl}`;
  try {
    const { data, status } = yield call(postData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.USER.GET_ROUNDS_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getUserRoles() {
  const url = `${API.getUserRolesUrl}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const formattedData =
        data &&
        data.data.map(item => {
          return {
            id: item,
            value: formatRoundingType(item)
          };
        });
      yield put({
        type: ACTIONS.USER.GET_USER_ROLES_SUCCESS,
        data: formattedData
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getFacilities() {
  const url = `${API.getFacilitiesUrl}`;
  const { AuthorizedUser } = yield select(state => state);

  const userAccess = AuthorizedUser.authorizedUser?.access?.accessLevel;
  const userAccessIds = AuthorizedUser.authorizedUser?.access?.accessLevelIds;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200 && Array.isArray(data)) {
      const sortedFacilities = data.sort(compareObjByStringKey('facility'));
      let filteredUserFacilities = [];

      switch (userAccess) {
        case 'division':
          filteredUserFacilities = sortedFacilities.filter(fac => {
            return userAccessIds.some(id => fac.division === id);
          });
          break;
        case 'master':
          filteredUserFacilities = sortedFacilities;
          break;
        case 'facility':
          filteredUserFacilities = sortedFacilities.filter(fac => {
            return userAccessIds.some(id => fac.facilityId === id);
          });
          break;
        default:
          filteredUserFacilities = sortedFacilities;
          break;
      }
      const formattedFilteredUserFacilities =
        filteredUserFacilities.length > 0 &&
        filteredUserFacilities.map(item => {
          return {
            id: item.facilityId,
            value: item.facility,
            division: item.division,
            isCSCEnabled: item.isCsrnEnabled ?? false,
            isCNEdEnabled: item.isCnedEnabled ?? false,
            isValidationEnabled: item.isValidationEnabled ?? false
          };
        });

      return yield all([
        put({
          type: ACTIONS.CONFIG.GET_FACILITIES_SUCCESS,
          data: sortedFacilities
        }),
        put({
          type: ACTIONS.USER.GET_FACILITIES_SUCCESS,
          data: formattedFilteredUserFacilities
        })
      ]);
    }
    return yield all([
      put({
        type: ACTIONS.CONFIG.GET_FACILITIES_FAILURE
      }),
      put({
        type: ACTIONS.USER.GET_FACILITIES_FAILURE
      })
    ]);
  } catch (error) {
    console.log(error);
    return yield all([
      put({
        type: ACTIONS.CONFIG.GET_FACILITIES_FAILURE
      }),
      put({
        type: ACTIONS.USER.GET_FACILITIES_FAILURE
      })
    ]);
  }
}

export function* getDivisions() {
  const url = `${API.getDivisionsUrl}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const formattedData =
        data &&
        data.map(item => {
          return { id: item, value: item };
        });
      yield put({
        type: ACTIONS.USER.GET_DIVISIONS_SUCCESS,
        data: formattedData
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* postUserAccess(action) {
  const url = `${API.postUserAccessUrl}`;
  const { adminUserFilter, adminSearchTerm, adminUserTab } = yield select(
    state => state.AdminReducer
  );

  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Access Granted!', 'success', 1000, 500);
      // appInsights.trackEvent({
      //   name: 'User_Permissions_Changed'
      // });
      yield put({
        type: ACTIONS.USER.POST_USER_ACCESS_SUCCESS,
        data
      });
      // Refresh users table
      return yield call(getUsers, {
        data: {
          page: 0,
          role:
            adminUserTab === 'allUsers'
              ? adminUserFilter?.userRole || ''
              : adminUserTab,
          accessLevel: adminUserFilter?.accessLevel || '',
          accessLevelId: adminUserFilter?.accessPlace || '',
          name: !isHca34(adminSearchTerm) ? adminSearchTerm.trim() : '',
          hcaId: isHca34(adminSearchTerm)
            ? adminSearchTerm.toLowerCase().trim()
            : ''
        }
      });
    }
    toast('Failed to grant access!', 'error', null, 500, true);
    // appInsights.trackEvent({
    //   name: 'User_Permissions_Failed'
    // });
    return yield put({
      type: ACTIONS.USER.POST_USER_ACCESS_FAILURE,
      data
    });
  } catch (error) {
    console.log(error);
    return toast('Failed to grant access!', 'error', null, 500, true);
  }
}

export function* postUserAccessRevoke(action) {
  const { adminUserFilter, adminSearchTerm, adminUserTab } = yield select(
    state => state.AdminReducer
  );

  const url = `${API.postUserAccessRevokeUrl}`;

  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Access Revoked!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.USER.POST_USER_ACCESS_REVOKE_SUCCESS,
        data
      });
      // Refresh users table
      return yield call(getUsers, {
        data: {
          page: 0,
          role:
            adminUserTab === 'allUsers'
              ? adminUserFilter?.userRole || ''
              : adminUserTab,
          accessLevel: adminUserFilter?.accessLevel || '',
          accessLevelId: adminUserFilter?.accessPlace || '',
          name: !isHca34(adminSearchTerm) ? adminSearchTerm.trim() : '',
          hcaId: isHca34(adminSearchTerm)
            ? adminSearchTerm.toLowerCase().trim()
            : ''
        }
      });
    }
    // return yield put({
    //   type: ACTIONS.USER.POST_USER_ACCESS_REVOKE_FAILURE,
    //   data
    // });
    return toast('Failed to revoke access!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to revoke access!', 'error', null, 500, true);
  }
}

export function* getDischarge(action) {
  const url = `${API.getDischargeUrl}`;
  const { facilityId } = action.data;
  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      // appInsights.trackEvent({
      //   name: 'Patient_Discharged'
      // });
      toast('Discharge was Successful!', 'success', 1000, 500);
      return yield all([
        put({
          type: ACTIONS.USER.GET_PATIENTS_TO_ROUND,
          data: { facilityId }
        }),
        put({
          type: ACTIONS.USER.GET_DISCHARGE_PATIENT_SUCCESS,
          data
        })
      ]);
    }
    return toast('Failed to discharge Patient!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to discharge Patient!', 'error', null, 500, true);
  }
}

export function* addEmployeesToUser(action) {
  const { hcaid } = yield select(state => state.AuthorizedUser.authorizedUser);
  const url = API.addEmployeeToUSer;
  try {
    const { status } = yield call(postData, url, action.data);

    if (status === 200) {
      toast('Employee Added!', 'success', 1000, 500);
      return yield put(getAuthorizedUser(hcaid));
    }
    return toast('Employee Failed to Add!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Employee Failed to Add!', 'error', null, 500, true);
  }
}

export function* removeEmployeeFromUser(action) {
  const url = API.removeEmployeeFromUser;
  try {
    const { status } = yield call(postData, url, action.data);

    if (status === 200) {
      toast('Employee Removed!', 'success', 1000, 500);
      const { hcaid } = yield select(
        state => state.AuthorizedUser.authorizedUser
      );
      yield put(getAuthorizedUser(hcaid));
      return yield put(getEmployeesToRound({ hcaid }));
    }
    return toast('Failed to Remove Employee!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to Remove Employee!', 'error', null, 500, true);
  }
}

export function* removeUserAsDelegate(action) {
  const url = API.removeDelegateFromUser;
  try {
    const { status } = yield call(postData, url, action.data);

    if (status === 200) {
      toast('User Removed!', 'success', 1000, 500);
      const { hcaid } = yield select(
        state => state.AuthorizedUser.authorizedUser
      );
      yield put(getAuthorizedUser(hcaid));
      return yield put(getEmployeesToRound({ hcaid }));
    }
    return toast('Failed to Remove User!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to Remove User!', 'error', null, 500, true);
  }
}

export function* postNewPic(action) {
  const { hcaid } = yield select(state => state.AuthorizedUser.authorizedUser);
  const multiFormData = new FormData();
  multiFormData.append('picture', action.data);
  try {
    const { status } = yield call(
      postData,
      `${API.postNewProfilePicUrl}?userId=${hcaid}`,
      multiFormData
    );
    if (status === 200) {
      toast('Picture Updated!', 'success', 1000, 500);
      yield put(getSassToken());
      yield delay(800);
      return yield put({
        type: ACTIONS.USER.POST_UPDATE_USER_PIC_SUCCESS
      });
    }
    return yield put({
      type: ACTIONS.USER.POST_UPDATE_USER_PIC_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.USER.POST_UPDATE_USER_PIC_FAILURE
    });
  }
}

export function* getPatientsToRoundForCensus(action) {
  const { roundingType, facilityId, startDate, endDate } = action.data;

  yield call(getPatientsToRound, { data: { facilityId } });
  const patientsToRound = yield select(
    state => state.UserReducer.patientsToRound
  );

  const url = `${API.getUserRoundHistoryUrl}?roundingType=${roundingType}&facilityId=${facilityId}&startDate=${startDate}&endDate=${endDate}`;

  try {
    const { status, data } = yield call(getData, url);
    if (status === 200) {
      const tempPatientsToRound = patientsToRound;
      data.forEach(history => {
        let duplicateFlag = false;
        if (
          history?.accountNum &&
          history.accountNum.substring(0, 2) === 'UL'
        ) {
          const patient = {
            roundStatus:
              history.completed &&
              history?.roundDate &&
              dt.isOrbitTimestamp(history?.roundDate) &&
              dt.isToday(dt.parseDate(history?.roundDate))
                ? 'Complete'
                : 'To Do',
            accountNum: history.accountNum,
            mrn: history.patientMRN,
            urn: '',
            encounterId: '',
            unitId: history.unitId,
            dateOfBirth: '',
            firstName: history.patientFirstName?.toUpperCase(),
            lastName: history.patientLastName?.toUpperCase(),
            room: history.room,
            bed: history.bed,
            admitTime: '',
            dischargeTime: '',
            patientStatus: '',
            transferred: false,
            sex: '',
            chiefComplaint: '',
            attendingPhysician: '',
            notes: [],
            lastRoundId: history.roundId,
            lastRoundDate: history.roundDate,
            formattedLastRoundDate: `${dt.dayOfYr(
              history?.roundDate
            )} @ ${dt.localTimeOfDay(history?.roundDate)}`,
            lengthOfStay: -1,
            lastRoundedBy: `${history.userFirstName} ${history.userLastName}`
          };

          tempPatientsToRound.forEach(eachPatient => {
            if (
              eachPatient.lastRoundId === patient.lastRoundId
              // TODO: add back when backend metrics are adjusted
              // || eachPatient.accountNum === patient.accountNum
            ) {
              duplicateFlag = true;
              // TODO: See above
              // if (
              //   duplicateFlag &&
              //   compareDesc(
              //     dt.parseDate(eachPatient.lastRoundDate),
              //     dt.parseDate(patient.lastRoundDate)
              //   ) === 1
              // ) {
              //   let dup = eachPatient;
              //   dup = { ...patient };
              // }
            }
          });
          if (!duplicateFlag) {
            tempPatientsToRound.unshift(patient);
          }
        }
      });
      yield put({
        type: ACTIONS.USER.GET_PATIENTS_TO_ROUND_FOR_CENSUS_SUCCESS,
        data: tempPatientsToRound
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addDelegateToUser(action) {
  const { hcaid } = yield select(state => state.AuthorizedUser.authorizedUser);
  const url = API.addDelegateToUser;
  try {
    const { status } = yield call(postData, url, action.data);

    if (status === 200) {
      toast('Delegate Added!', 'success', 1000, 500);
      yield put(getAuthorizedUser(hcaid));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* removeDelegateFromUser(action) {
  const { hcaid } = yield select(state => state.AuthorizedUser.authorizedUser);
  const url = API.removeDelegateFromUser;
  try {
    const { status } = yield call(postData, url, action.data);

    if (status === 200) {
      // yield put({
      //   type: ACTIONS.EMPLOYEE.REMOVE_FROM_MY_EMPLOYEES,
      //   data: action.data.employeeId
      // });
      toast('Delegate Removed!', 'success', 1000, 500);
      yield put(getAuthorizedUser(hcaid));
    }
  } catch (error) {
    console.log(error);
  }
}
// user/employee/revoke list access is the correct endpoint with userId and DelegateId
export function* postEmployeeListConfig(action) {
  const { hcaid } = yield select(state => state.AuthorizedUser.authorizedUser);
  const url = API.postEmployeeListConfigUrl;
  try {
    const { status } = yield call(postData, url, action.data);

    if (status === 200) {
      yield put(getAuthorizedUser(hcaid));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getEmployeeNotes(action) {
  const { selectedEmployee } = yield select(state => state.UserReducer);

  const url = `${API.getEmployeeNotesUrl}?employeeId=${action.data}`;
  try {
    const { data, status } = yield call(getData, url);

    if (status === 200) {
      const selectedEmpWithNotes = {
        ...selectedEmployee,
        notes: data
      };
      yield put({
        type: ACTIONS.USER.SET_SELECTED_EMPLOYEE,
        data: selectedEmpWithNotes
      });
    }
  } catch (error) {
    console.log(error);
  }
}
