import styled from 'styled-components';

import {
  NeuTableBody,
  NeuTable,
  NeuInput,
  NeuItem,
  NeuTableRow
} from '@neutron/react';

interface DropdownProps {
  showPopover: boolean;
}

export const MyEmployeesContainer = styled.div`
  width: 100%;
`;

export const RoundingConfigContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #b0b0b0;
`;

export const RoundingConfigHeader = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b;
`;

export const DropdownOptions = styled.p`
  width: 350px;
`;

export const MyEmployeesTable = styled(NeuTable)`
  background: #ffffff;
  border-radius: 5px;
  height: 100%;
`;

export const MyEmployeesTableBody = styled(NeuTableBody)`
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
`;

export const EmployeeSlot = styled.div`
  display: flex;
  gap: 5px;
`;

export const EmployeeAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

export const EmployeeNameInTable = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #093865;
`;

export const TextInTable = styled.p`
  font-size: 14px;
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  text-align: left;
  color: #4b4b4b;
`;

export const Meta = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
`;

export const ManagerNameInTable = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #093865;
`;

export const RemoveButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const FacilityInput = styled.div`
  width: 35%;
`;

export const ModalInput = styled(NeuInput)`
  width: 97%;
  min-height: 42px;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 97%;
  min-height: 27px;
  max-height: 160px;
  z-index: 10;
  position: absolute;
  top: 42px;
  background: white !important;
  opacity: 1;
  overflow-y: scroll;
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  padding: 1px;
  opacity: 1;
  overflow: hidden;
`;

export const ContentHeader = styled.div`
  margin-bottom: 10px !important;
  padding-top: 15px;
`;

export const EmployeeRow = styled(NeuTableRow)`
  background-color: white;
  height: 64px;
  align-items: center;
`;

export const SupportListStyleContainer = styled.div`
  height: calc(100% - 30px);
`;
