// Importing action file
import ACTIONS from '../actions/actionType';
import { ActionType } from '../../config/interfaces';

const initialState = {
  isCSCEnabled: true,
  isCNEdEnabled: true,
  isValidationEnabled: true
};

const FeatureReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.FEATURE.SET_FEATURES:
      return {
        ...state,
        ...action.data
      };

    default:
      return tempState;
  }
};

export default FeatureReducer;
