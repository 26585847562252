import ACTIONS from './actionType';

// Employee actions
export const getEmployees = data => {
  return { type: ACTIONS.EMPLOYEE.GET_EMPLOYEES, data };
};

export const searchEmployees = data => {
  return { type: ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES, data };
};

export const searchEmployeesToShare = data => {
  return { type: ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES_TO_SHARE, data };
};

export const clearEmployeeSearch = data => {
  return { type: ACTIONS.EMPLOYEE.CLEAR_EMPLOYEE_SEARCH, data };
};

export const setDelegatedEmployee = data => {
  return {
    type: ACTIONS.EMPLOYEE.SET_DELEGATED_EMPLOYEE,
    data
  };
};

export const getEmployeesToRound = data => {
  return { type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND, data };
};

export const getCSRNSubjectsToRoundForList = data => {
  return { type: ACTIONS.EMPLOYEE.GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST, data };
};

export const getCNEdSubjectsToRoundForList = data => {
  return { type: ACTIONS.EMPLOYEE.GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST, data };
};

export const getValidationSubjectsToRoundForList = data => {
  return {
    type: ACTIONS.EMPLOYEE.GET_VALIDATION_SUBJECTS_TO_ROUND_FOR_LIST,
    data
  };
};

export const getEmployeesConfig = data => {
  return { type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_DELEGATED, data };
};

export const getCSCEmployeesConfig = data => {
  return { type: ACTIONS.EMPLOYEE.GET_CSC_EMPLOYEES_CONFIG, data };
};

export const updateCSCHiddenStatus = data => {
  return { type: ACTIONS.EMPLOYEE.UPDATE_CSC_EMPLOYEES_HIDDEN_STATUS, data };
};

export const getCNEdEmployeesConfig = data => {
  return { type: ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG, data };
};

export const updateCNEdHiddenStatus = data => {
  return { type: ACTIONS.EMPLOYEE.UPDATE_CNED_EMPLOYEES_HIDDEN_STATUS, data };
};

export const getValidationEmployeesConfig = data => {
  return { type: ACTIONS.EMPLOYEE.GET_VALIDATION_EMPLOYEES_CONFIG, data };
};

export const updateValidationHiddenStatus = data => {
  return {
    type: ACTIONS.EMPLOYEE.UPDATE_VALIDATION_EMPLOYEES_HIDDEN_STATUS,
    data
  };
};

export const getMyEmployees = data => {
  return { type: ACTIONS.EMPLOYEE.GET_MY_EMPLOYEES, data };
};

export const getDelegateSettings = data => {
  return { type: ACTIONS.EMPLOYEE.GET_DELEGATE_SETTINGS, data };
};

export const getEmployeeAccountDetails = data => {
  return { type: ACTIONS.EMPLOYEE.GET_EMPLOYEE_ACCOUNT_DETAILS, data };
};

export const postEmployeeNote = data => {
  return { type: ACTIONS.EMPLOYEE.ADD_NOTE, data };
};

export const removeEmployeeNote = data => {
  return { type: ACTIONS.EMPLOYEE.REMOVE_NOTE, data };
};

export const clearEmployeesToRound = () => {
  return { type: ACTIONS.EMPLOYEE.CLEAR_EMPLOYEES_TO_ROUND };
};

export const setDepts = data => {
  return { type: ACTIONS.EMPLOYEE.SET_DEPTS, data };
};

export const setSelectedDepts = data => {
  return { type: ACTIONS.EMPLOYEE.SET_SELECTED_DEPTS, data };
};

export const clearAllDepts = () => {
  return { type: ACTIONS.EMPLOYEE.CLEAR_ALL_DEPTS };
};
