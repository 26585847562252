import styled from 'styled-components';
import { NeuAvatar } from '@neutron/react';

export const SubToolbar = styled.div`
  position: static;
  width: 100%;
  min-height: 95px;
  background: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 5px;
  font-weight: 700;
  padding-left: 0px;
`;

export const AccountHeader = styled.span`
  color: #093865;
  font-size: 1rem;
  margin-top: 10px;
  margin-left: 15px;
  display: flex;
  flex: 1;
`;

export const AccountInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  color: #4b4b4b;
  font-size: 14;
  line-height: 22px;
  font-style: normal;
  font-weight: 700;
  // box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  & span:nth-child(1) {
    flex-basis: 22%;
  }
  & span:nth-child(2) {
    flex-basis: 22%;
  }
  & span:not(:first-child) {
    margin: 0px 1em;
  }
`;

export const TitleContainer = styled.div`
  width: 112px;
  height: 22px;
  left: 20px;
  top: 0px;
  font-size: 14px;
  line-height: 22px;
  color: #2075ad;
  flex-direction: row;
`;

export const Title = styled.p`
  color: #093865;
  font-weight: bold;
  font-size 16px;
  line-height: 26px;
`;

export const UserInfo = styled.span`
  white-space: pre;
  font-weight: normal;
  font-weight: 400;
  color: #686868;
  display: block;
`;

export const HeaderTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b;
  text-wrap: nowrap;
`;

export const UserAvatar = styled(NeuAvatar)`
  height: 50px;
  width: 50px;
`;

export const EditButton = styled.button`
  background: transparent;
  color: #2075ad;
  font-size: 0.85rem;
  font-weight: 700;
  width: fit-content;
`;

export const EditContainer = styled.div`
  margin-left: 25px;
  padding: 0.3rem 0;
`;
